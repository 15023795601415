import { Controller } from 'stimulus';
import { copyTextToClipboard } from '../../services/copy_text_to_clipboard';

export default class extends Controller {
  static targets = ['slugInput', 'prefix'];

  copyToClipboard(event) {
    event.preventDefault();
    const prefix = this.prefixTarget.innerHTML;
    const slug = this.slugInputTarget.value || this.slugInputTarget.innerHTML;
    this.handleCopy(prefix + slug, this.slugInputTarget);
  }

  copyCourseLink(event) {
    const hiddenInput = this.element.querySelector('input[type="hidden"]');
    const courseUrl = hiddenInput.value;
    this.handleCopy(courseUrl, hiddenInput);
  }

  copySchoolLink(event) {
    const hiddenInput = this.element.querySelector('input[type="hidden"]');
    const path = hiddenInput.value;
    const fullUrl = `${window.location.origin}${path}`;
    this.handleCopy(fullUrl, hiddenInput);
  }

  handleCopy(text, inputElement) {
    const copiedSuccessfully = copyTextToClipboard(text);

    if (copiedSuccessfully) {
      $('.notifyjs-wrapper').trigger('notify-hide');
      $(inputElement).parent().notify(I18n.copy_ok || '', 'success');
    }
  }
}
